import ImmaxLogo from "@public/images/partners/immax.svg";

export function PartnerSection() {
    return (
        <div className="container max-w-[1088px] px-8 xs:-mt-4 xl:-my-4">
            <div className="flex items-center">
                <span className="uxf-typo-h5 text-center text-textHigh">Partnerem projektu&nbsp;je</span>
                <a href="https://www.immax.cz/" target="_blank" rel="noopener noreferrer">
                    <ImmaxLogo className="ml-2 w-[110px]" alt="immax" />
                </a>
            </div>
        </div>
    );
}
