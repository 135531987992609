import { routeToUrl } from "@app-routes";
import CabelLeft from "@public/images/illustrations/cabel-left.svg";
import CabelRight from "@public/images/illustrations/cabel-right.svg";
import CentropolLogo from "@public/images/partners/centropol.svg";
import EnergiePomahaLogo from "@public/images/partners/energie-pomaha.svg";
import ImmaxLogo from "@public/images/partners/immax.svg";
import { Button } from "@uxf/ui/button";
import { RasterImage } from "@uxf/ui/raster-image";
import Link from "next/link";

export const RegistrationSection = () => {
    return (
        <>
            <div className="flex flex-col items-center justify-center space-y-6 px-6 pb-24 sm:pt-12 md:space-y-10 md:px-0 md:pb-24">
                <h2 className="max-w-[750px] text-center text-mobileH4 font-bold text-textHigh md:text-desktopH3">
                    Registrujte i&nbsp;vaši školu do&nbsp;programu Energetická Expedice
                </h2>
                <Link href={routeToUrl("auth/register")} passHref legacyBehavior>
                    <Button size="xl" className="px-6 py-3 max-sm:w-5/6">
                        Registrovat školu
                    </Button>
                </Link>
            </div>
            <div className="relative sm:pb-20">
                <div>
                    <CabelLeft
                        alt=""
                        className="absolute -bottom-12 -left-72 h-[60px] w-[545px] xs:-bottom-20 xs:-left-44 sm:-bottom-6 sm:-left-64 sm:h-[126px] md:-left-60 md:bottom-2 lg:-left-20"
                    />
                    <p className="uxf-typo-h5 pb-4 text-center text-textHigh">Partner projektu</p>
                    <div className="flex items-center justify-center space-x-10 px-5 xs:space-x-20 md:px-0">
                        <Link href="https://www.immax.cz/" target="_blank" rel="noopener noreferrer">
                            <ImmaxLogo className="w-[100px] xs:w-[110px]" />
                        </Link>
                    </div>
                    <p className="uxf-typo-h5 pb-8 pt-12 text-center text-textHigh">Garanti projektu</p>
                    <div className="flex items-center justify-center space-x-10 px-5 xs:space-x-20 md:px-0">
                        <Link href="https://www.centropol.cz/" target="_blank" rel="noopener noreferrer">
                            <CentropolLogo className="h-[45px] w-[165px] xs:w-[205px]" />
                        </Link>
                        <Link href="https://energie-pomaha.cz/" target="_blank" rel="noopener noreferrer">
                            <EnergiePomahaLogo className="h-[40px] w-[85px] xs:w-[115px]" />
                        </Link>
                    </div>
                    <p className="uxf-typo-h5 pb-6 pt-24 text-center text-textHigh">Záštity projektu</p>
                    <div className="grid grid-rows-2 justify-items-center gap-12 md:flex md:justify-center">
                        <Link href="/documents/diplom_MŠMT_2024.pdf" target="_blank">
                            <RasterImage
                                alt="Certifikat záštity MŠMT"
                                className="max-w-[300px] border-[1px]"
                                src="/images/partners/certificate-msmt.png"
                                width={600}
                            />
                        </Link>
                        <Link href="/documents/diplom_MPO_2024.pdf" target="_blank">
                            <RasterImage
                                alt="Certifikat záštity MPO"
                                className="max-w-[300px] border-[1px]"
                                src="/images/partners/certificate-mpo.png"
                                width={600}
                            />
                        </Link>
                    </div>
                    <CabelRight
                        alt=""
                        className="absolute -right-80 -top-12 h-[52px] w-[680px] xs:-right-64 sm:h-[101px] md:-right-64 md:top-2 lg:-right-40"
                    />
                </div>
            </div>
        </>
    );
};
