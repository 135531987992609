import { type GetStaticProps } from "@app-routes";
import { Footer } from "@shared/components/footer/footer";
import { HeaderHomepage } from "@shared/components/header/components/header-homepage";
import { Helmet } from "@shared/components/helmet/helmet";
import { AboutSection } from "@ui/components/homepage/about-project/about-section";
import { FaqSection } from "@ui/components/homepage/faq/faq-section";
import { Jumbotron } from "@ui/components/homepage/jumbotron/jumbotron";
import { PartnerSection } from "@ui/components/homepage/partner-section/partner-section";
import { References } from "@ui/components/homepage/references/references";
import { RegistrationSection } from "@ui/components/homepage/registration-section/registration-section";

export default function Page() {
    return (
        <div>
            <Helmet
                title="Interaktivní vzdělávání v energetice pro 2. stupeň základních škol"
                metaTagData={{
                    metaDescription:
                        "Základy energetické gramotnosti zábavně online. Praktické využití znalosti z fyziky. Vliv spotřeby elektřiny a plynu na rozpočet. Ověřeno dětmi i vyučujícími.",
                }}
            />
            <HeaderHomepage />
            <Jumbotron />
            <PartnerSection />
            <AboutSection />
            <References />
            <RegistrationSection />
            <FaqSection />
            <Footer />
        </div>
    );
}

export const getStaticProps: GetStaticProps<"index"> = async () => {
    return { props: {} };
};
